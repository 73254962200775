import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import './MultiStepForm.css';

function MultiStepForm() {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = ['email', 'projectName', 'description', 'price', 'timeline', 'relevance'];
  
  const [formData, setFormData] = useState({
    email: '',
    projectName: '',
    description: '',
    price: '',
    timeline: '',
    relevance: '',
  });

  const [state, handleSubmit] = useForm("mzbqzbwj"); // Use your actual Formspree form ID
  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderInputField = (fieldName) => {
    switch (fieldName) {
      case 'email':
        return (
          <>
            <label htmlFor="email">Mailadress</label>
            <input
              id="email"
              type="email" 
              name="email"
              onChange={handleInputChange}
              value={formData.email}
            />
            <ValidationError prefix="Email" field="email" errors={state.errors} />
          </>
        );
      case 'projectName':
        return (
          <>
            <label htmlFor="projectName">Tjänsten du vill benchmarka mot</label>
            <input
              id="projectName"
              type="text"
              name="projectName"
              onChange={handleInputChange}
              value={formData.projectName}
            />
          </>
        );
      case 'description':
        return (
          <>
            <label htmlFor="description">Beskrivning av ditt projekt</label>
            <textarea
              id="description"
              name="description"
              onChange={handleInputChange}
              value={formData.description}
            />
          </>
        );
      case 'price':
        return (
          <>
            <label htmlFor="price">Budget du tänkt dig</label>
            <input
              id="price"
              type="text"
              name="price"
              onChange={handleInputChange}
              value={formData.price}
            />
          </>
        );
      case 'timeline':
        return (
          <>
            <label htmlFor="timeline">Timeline of Your Project</label>
            <input
              id="timeline"
              type="text"
              name="timeline"
              onChange={handleInputChange}
              value={formData.timeline}
            />
          </>
        );
      case 'relevance':
        return (
          <>
            <label htmlFor="relevance">Is Price or Timeline More Important?</label>
            <input
              id="relevance"
              type="text"
              name="relevance"
              onChange={handleInputChange}
              value={formData.relevance}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="content">
      <div className="intro-text">
        <h1>Kollis</h1>
              <img src="logo.png" alt="Company Logo" className="logo" />

        <h2>Allt du behöver samlat på ett ställe.</h2>
        {/* Place your images or logos here */}
        <h3>Få en benchmark av alternativa köp inom 48h!</h3>
      </div>
      <div className="form-card">
        <form onSubmit={handleSubmit}>
          {renderInputField(steps[currentStep])}
          <div className="form-navigation">
            {currentStep > 0 && (
              <button type="button" className="previous" onClick={previousStep}>
                Previous
              </button>
            )}
            {currentStep < steps.length - 1 && (
              <button type="button" className="next" onClick={nextStep}>
                Next
              </button>
            )}
            {currentStep === steps.length - 1 && (
              <button type="submit" disabled={state.submitting}>
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
      {state.succeeded && <p>Done, we are analyzing your request.</p>}
      <footer className="footer">
        <p>© 2024 Kollis. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default MultiStepForm;
