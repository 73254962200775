// FAQ.js
import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: 'Hur funkar kollis?',
      answer: 'Kollis aggregates candidates from various platforms and presents you with the best options for your project needs.'
    },
    {
      question: 'How long does it take to get a kollis?',
      answer: 'The shortlist process is typically completed in less than 48 hours.'
    },
    {
      question: 'Finns det support för kollis?',
      answer: 'Yes, there is a nominal fee for the shortlisting service which depends on the complexity of the job.'
    },
    // ... add more FAQs as needed
  ];

  const toggleFAQ = index => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="faq-container">
      <h2 className="faq-header">Frequently Asked Questions</h2>
      <div className="faq-card">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {faq.question}
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
