import React from 'react';
import './App.css';
import MultiStepForm from './MultiStepForm';
import FAQ from './FAQ';

function App() {
  return (
    <div className="App">
      <MultiStepForm />
      <FAQ />

    </div>
  );
}

export default App;
